<template>
    <div class="container is-fluid">
        <div class="is-flex py-2">
            <router-link :to="'/'" class="button  is-responsive is-outlined" >
                Return to dashboard
            </router-link>
            <!-- <p class="is-size-5 ml-4 has-text-info-dark is-pulled-left">
                <i class="fas fa-boxes mr-2"></i> 
                User Roles  
            </p> -->
        </div>
        <div class="columns pt-4">
            <div class="column " :class="[role_user_tab == 'users' ? 'is-6' : 'is-4']">
                <div class="tabs is-boxed mb-3">
                    <ul>
                        <li :class="{ 'is-active': role_user_tab == 'roles' }">
                            <a @click.prevent="change_roles_users('roles')"><span class="is-size-6 ">{{ $t('roles') }}</span></a>
                        </li>
                        <li :class="{ 'is-active': role_user_tab == 'users' }">
                            <a @click.prevent="change_roles_users('users')"><span class="is-size-6 is-capitalized">{{ $t('users') }}</span></a>
                        </li>
                    </ul>
                </div>
                <!-- Roles section -->
                <div v-if="role_user_tab == 'roles'" >
                    <p>
                        {{ $t('select_role') }}
                        <a v-if="!show_create_new_role && am_allowed('user_roles.can_create_role')" @click.prevent="start_create_new_role()" href="" class="is-underlined is-pulled-right">
                            <i class="fas fa-plus mr-1"></i>
                        </a>
                    </p>
                    <!-- Create new user_type (Role) -->
                    <div v-if="show_create_new_role" class="has-border p-3 my-3">
                        <p>
                            <span class="is-size-7">
                                {{ $t('create_new_role') }}
                            </span>
                            <button @click.prevent="close_create_new_role()" class="delete is-small is-pulled-right"></button>
                        </p>
                        <div>
                            <input v-model="new_role.name" class="input is-success is-small" type="text" placeholder="Role name">
                            <div class="mt-2">
                                <a @click.prevent="create_role()" href="" class="button is-success is-small is-fullwidth">{{ $t('create_role_btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <!-- Role table -->
                    <table class="table is-striped is-bordered is-fullwidth is-narrow is-hoverable ">
                        <tbody>
                            <tr  v-for="(user_type, index) in user_types_list" :key="index" class="">
                                <td @click="change_selected_role(user_type)" class="has-text-centered is-vcentered" width="6%">
                                    <input :checked="user_type.id == selected_role_data.id" type="radio" name="answer">
                                </td>
                                <td>
                                    <div v-if="!user_type.edit_mode">
                                        <p class="is-flex" >
                                            <span class="is-flex-grow-1 is-clickable" @click="change_selected_role(user_type)">
                                                {{user_type.name}}
                                            </span>
                                            <a v-if="am_allowed('user_roles.can_edit_role')" @click.prevent="start_show_edit_role(user_type)" href="" class="is-pulled-right">
                                                <small ><i class="fas fa-pencil-alt"></i></small>
                                            </a>
                                        </p>
                                    </div>
                                    <div v-else class="field has-addons">
                                        <p class="control is-expanded">
                                            <input v-model="user_type.new_name" class="input is-success is-small" type="text" :placeholder="$t('role_name')">
                                        </p>
                                        <p class="control">
                                            <a @click.prevent="start_show_edit_role(user_type)" class="button is-small">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </p>
                                        <p class="control">
                                            <a @click.prevent="save_user_type(user_type)" class="button is-small">
                                                <i class="fa fa-check"></i>
                                            </a>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Users section -->
                <div v-if="role_user_tab == 'users' ">
                    <p>
                        {{ $t('assign_role_to_user') }}
                        <a v-if="am_allowed('user_roles.can_create')" @click.prevent="start_create_new_user()" href="" class="is-underlined is-pulled-right">
                            <i class="fas fa-plus mr-1"></i>
                        </a>
                    </p>
                    <div v-if="show_create_new_user" class="has-border p-3 my-3">
                        <p>
                            <span class="is-size-7">
                                {{ $t('create_new_user') }}
                            </span>
                            <button @click.prevent="close_create_new_user()" class="delete is-small is-pulled-right"></button>
                        </p>
                        <div>
                            <!-- <input v-model="new_user.name" class="input is-success is-small" type="text" placeholder="Search user"> -->
                            <model-list-select :list="options1"
                                                v-model="new_user"
                                                option-value="code"
                                                option-text="name"
                                                placeholder="select item">
                            </model-list-select>

                            <!-- <model-list-select
                                :list="users_list"
                                option-value="code"
                                option-text="name"
                                v-model="new_user"
                                placeholder="New user"
                                @searchchange="search_user"
                                >
                            </model-list-select> -->
                            <div class="mt-2">
                                <a @click.prevent="" href="" class="button is-success is-small is-fullwidth">{{ $t('create_user_btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <table class="table is-striped is-bordered is-fullwidth is-narrow is-hoverable">
                        <tbody>
                            <tr v-for="(user, index) in users_list" :key="index">
                                <td>
                                    <span>
                                        {{user.name}}
                                    </span>
                                    <div class="is-pulled-right">
                                        <div v-if="!user.edit_mode">
                                            {{ user.type_id > 0 ? user.role.name : user.type_id == -1 ? $t('system_admin') : 'No role assigned' }} 
                                            <a v-if="am_allowed('user_roles.can_edit')" @click.prevent="start_edit_user(user)" href="">
                                                <i class="fas fa-pencil-alt ml-1"></i>
                                            </a>
                                        </div>
                                        <div v-else class="">
                                            <div class="field has-addons">
                                                <div class="control is-expanded">
                                                    <div class="select is-fullwidth is-primary is-small">
                                                        <select v-model="user.type_id">
                                                            <!-- <option value="-1" >Select role</option> -->
                                                            <option v-for="(role,index) in user_types_list" :key="index" :value="role.id">{{ role.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="control">
                                                    <button @click.prevent="start_edit_user(user)" type="submit" class="button is-primary is-outlined is-small"><i class="fas fa-times"></i></button>
                                                </div>
                                                <div class="control">
                                                    <button @click.prevent="assign_role(user)" type="submit" class="button is-primary is-small"><i class="fas fa-check"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="role_user_tab == 'roles'" class="column is-8">
                <!-- Role Permissions section -->
                <div v-if="role_user_tab == 'roles'">
                    <div v-if="selected_role_data.id > 0 " class="">
                        <div>
                            <p class="is-size-5">
                                {{selected_role_data.name}}
                            </p>
                        </div>
                        <div v-for="(key, index) in user_role_permissions_modules" :key="index" class="has-border mb-2">
                            <p class="py-1 px-2 has-text-dark" style="background: aliceblue;">
                                <span class="is-capitalized">
                                    {{$t(key.module_name + '_module')}}
                                </span>
                                <span @click="check_all_group(key)" class="is-pulled-right has-text-grey is-size-7 is-clickable">
                                    <span v-if="are_all_checked(key)">
                                        <i class="far fa-square"></i> {{ $t('uncheck_all') }}
                                    </span>
                                    <span v-else>
                                        <i class="far fa-check-square"></i> {{ $t('check_all') }}
                                    </span>
                                </span>
                            </p>
                            <div class="is-flex is-flex-wrap-wrap">
                                <div v-for="(permission, indexa) in key.permissions" :key="indexa" class=" has-border is-flex is-flex-direction-column" 
                                    style="width:15%;">
                                    <span class="is-flex " style="">
                                        <small class="mb-1 pt-1 pl-1" style="font-size:12px;" >
                                            <span v-if="Number.isInteger(permission.value) && (permission.value != permission.original_value)" class="transparent-btn is-small pl-1" :content="$t('save_changes_permissions')"
                                                v-tippy='{ 
                                                    arrow : true, 
                                                    arrowType : "round" }'>
                                                <i  class="fas fa-exclamation-triangle is-small has-text-danger"></i>
                                            </span>
                                            {{$t(key.module_name + '_' + permission.name)}}
                                        </small>
                                        <span class="transparent-btn is-small pl-1 ml-auto mr-1" :content="permission.description" 
                                            v-tippy='{ 
                                                arrow : true, 
                                                arrowType : "round" }'>
                                            <i class="fas fa-info-circle has-text-info " style="opacity:0.5;"></i>
                                        </span>
                                    </span>
                                    <div class="is-flex-grow-2 is-flex is-justify-content-center is-align-items-center">
                                        <label :disabled="!am_allowed('user_roles.can_edit_permissions')" class="checkbox py-1 px-6">
                                            <input v-model="permission.value" :disabled="!am_allowed('user_roles.can_edit_permissions')" true-value=1 false-value=0  type="checkbox">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <a v-if="have_changed && am_allowed('user_roles.can_edit_permissions')" @click.prevent="save_role_permissions()" class="button is-success is-small is-fullwidth mb-3" href="">{{ $t('save_changes') }}</a>
                        </div>
                        <div v-for="(permission , index) in permissions_list" :key="index">
                            <div class="has-border p-2 mb-1">
                                {{permission}}
                            </div>
                        </div>
                    </div>
                    <div v-else class="column is-flex is-align-items-center is-justify-content-center">
                        <span class="has-border p-3">
                            {{ $t('please_select_role') }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <create-user-modal></create-user-modal>
    </div>
</template>

<script>
import axios from 'axios'
import permissionMixin from '@/permissions-mixin.js'
import { EventBus } from '@/event-bus'
import { ModelListSelect } from 'vue-search-select'


export default {
    mixins: [permissionMixin],

    components: {
        'create-user-modal': () => import('@/components/modals/create-user-modal.vue'),
        ModelListSelect
    },

    data() {
        return {
            user_types_list: [],
            permissions_list: {},
            show_create_new_role: false,
            new_role: {},
            show_permissions_section: false,
            show_create_permission: false,
            new_permission: {},
            show_edit_role: false,
            selected_role_data: {},
            role_user_tab: 'roles',
            users_list: [],
            show_create_user_modal: false,
            show_create_new_user: false,
            new_user: {},

            options1: [
                { code: '01', name: 'aa', desc: 'desc01' },
                { code: '02', name: 'ab', desc: 'desc02' },
                { code: '03', name: 'bc', desc: 'desc03' },
                { code: '04', name: 'cd', desc: 'desc04' },
                { code: '05', name: 'de', desc: 'desc05' },
                { code: '06', name: 'ef', desc: 'desc06' }
            ],
        }
    },
    created() {
        Promise.all(
            [
                axios.post(this.$tetris_server +'/get/user/types')
                .then(res => {
                    this.user_types_list = res.data.map(ut => {
                        ut.edit_mode = false
                        ut.new_name = ut.name
                        return ut
                    })
                    return res.data
                }),

                axios.get(this.$tetris_server +'/user/get/users')
                .then(res => {
                    this.users_list = res.data.map(u => {
                        u.edit_mode = false
                        return u
                    })
                    return res.data
                })
            ]
        ).then(() => {
            this.users_list = [...this.users_list.map(u => {
                u.role = { id: 0 }
                this.user_types_list.map(ut => {
                    if(u.type_id == ut.id){
                        u.role = ut
                    }
                })
                return u
            })]

        })
        // Set app_route for hepls routes
        EventBus.$emit('set_route', this.get_url())
        EventBus.$emit('set_lang', this.$i18n.locale)

    },
    computed: {
        have_changed(){
            let change_found = false
            this.user_role_permissions_modules.map(m => {
                m.permissions.map(p => {
                    if(typeof p.value === 'string'){
                        p.value = parseInt(p.value)
                    }
                    if(Number.isInteger(p.value)){
                        if(p.value != p.original_value){
                            change_found = true
                        }
                    }
                })
            })
            return change_found
        }
    },
    methods: {

        get_user_types(){
            axios.post(this.$tetris_server +'/get/user/types')
            .then(res => {
                this.user_types_list = res.data.map(ut => {
                    ut.edit_mode = false
                    ut.new_name = ut.name
                    return ut
                })
            })
        },
        start_create_new_role(){
            this.show_create_new_role = !this.show_create_new_role
        },
        close_create_new_role(){
            this.show_create_new_role = !this.show_create_new_role
            this.new_role = {}
        },
        create_role(){
            axios.post(this.$tetris_server +'/create/role', this.new_role)
            .then(r => {
                if(r.data.id){
                    this.user_types_list.push(r.data)
                    this.close_create_new_role()
                    // Toast
                    this.$toast('Role '+ r.data.name +' has been created successfully', "success")
                }
            })
        },
        change_selected_role(user_type){
            this.selected_role_data = {...user_type}
            // Get user type permissions
            axios.post(this.$tetris_server +'/get/user/type/permissions', { 'type_id': this.selected_role_data.id })
            .then(res => {
                if(res.data.length > 0 ){
                    this.permissions_merge(res.data, 1)
                } else {
                    var a = []
                    this.permissions_modules.map(m => {
                        var b = {}
                        b.id = 0
                        b.type_id = this.selected_role_data.id
                        b.name = m.module_name
                        b.permissions_values = ''

                        m.permissions.map(() => {
                            b.permissions_values += '0'
                        })
                        a.push(b)
                    })
                    this.permissions_merge(a, 1)
                }
            })
            this.show_permissions_section = true

        },

        close_add_new_permission(){
            this.show_create_permission = false
        },
        save_role_permissions(){
            var permissions_values = []
            this.user_role_permissions_modules.map((p) => {
                var permissions_values_data = {
                    id: p.id,
                    name: p.module_name,
                    permissions_values: ""
                }
                p.permissions.map((pp) => {
                    permissions_values_data.permissions_values += (pp.value ? 1 : 0)
                })
                permissions_values.push(permissions_values_data)
            })
            axios.post(this.$tetris_server +'/save/role/permission', { 'type_id': this.selected_role_data.id, 'permissions_data': permissions_values })
            .then(() => {
                // Set original_value = value
                this.user_role_permissions_modules = [...this.user_role_permissions_modules.map(m => {
                    m.permissions.map(p => {
                        p.original_value = p.value
                        m.id = p.id
                    })
                    return m
                })]

                
                this.$toast( this.$t('permission_saved_successfuly'), "success")

                setTimeout( () => {
                    window.location.reload()
                }, 1000)
            })
        },
        save_user_type(role){
            axios.post(this.$tetris_server +'/save/user/type', { 'user_type': role}).then(res => {
                if(res.data == 1){
                    // 
                    this.user_types_list.map(ut => {
                        if(ut.id == role.id){
                            ut.name = role.new_name
                            ut.edit_mode = false
                            ut.new_name = ut.name
                        }
                        return ut
                    }) 
                    // Selected_role_data
                    if(this.selected_role_data.id == role.id){
                        this.selected_role_data.name = role.new_name
                    }
                    // Toaster
                    this.$toast( this.$t('permission_saved_successfuly_toast', {role_name: this.selected_role_data.name}), "success")
                }
            })
        },
        start_show_edit_role(role){
            role.edit_mode = !role.edit_mode
            if(!role.edit_mode){
                role.new_name = role.name
            }
        },
        change_roles_users(v){
            this.selected_role_data = {}
            this.role_user_tab = v 
        },
        check_all_group(k){
            // 1. Check if all are checked
            let have_empty = []
            k.permissions.map(i => {
                if(!i.value){ have_empty.push(i) }
            })

            // 2. If all are checked -> uncheck all
            if(have_empty.length == 0){
                k.permissions.map(a => {
                    a.value = 0
                    return a
                })
            } else { // 3. else -> Check all
                k.permissions.map(a => {
                    if(a)
                    a.value = 1
                    return a
                })
            }
        },
        are_all_checked(k){
            let have_empty = []
            k.permissions.map(i => {
                if(!i.value){ have_empty.push(i) }
            })
            return have_empty.length == 0 ? true : false
        },
        start_edit_user(u){
            u.edit_mode = !u.edit_mode

            if(u.edit_mode)
                console.log('open')
            else{
                console.log('u', u)
                if(u.type_id != -1){
                    u.type_id = u.role.id
                } else {
                    u.type_id = -1
                }
            }
        },
        assign_role(user){
            // return console.log(u, this.users_list, this.user_types_list)
            axios.post(this.$tetris_server +'/user/assign/user/role', user)
            .then(res => {
                if(res.data > 0){
                    this.users_list = [...this.users_list.map(u => {
                        if(u.guid == user.guid){
                            this.user_types_list.map(ut => {
                                if(u.type_id == ut.id) u.role = ut
                            })
                        }
                        u.edit_mode = false
                        return u
                    })]
                    this.$toast( this.$t('role_assigned_to_user', {role_name: user.role.name, user_name: user.name}), "success")
                }
            })
        },
        start_create_new_user(){
            this.show_create_new_user = true
            // this.show_create_user_modal = true
        },
        close_create_user_modal(){
            this.show_create_user_modal = false
        },
        get_url(){
            var url = this.$route.path.split('/')
            var route = ''
            if(url[1] == ''){
                route = '/'
            } else {
                route = url[1]
            }
            return route
        },
        close_create_new_user(){
            this.show_create_new_user = false
            this.new_user = {}
        },
        // search_user(text){ }
    },

}
</script>

<style>
    .tabe-content{
        border:solid #dedede;
        border-width:0px 1px 1px;
    }

</style>
